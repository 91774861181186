// ===============================
//
// Functions
//
// ===============================

	//
	// Breakpoint
	//
	
		@mixin breakpoint($breakpoint) {	
			@if map-has-key($breakpoints, $breakpoint) {
				@media (max-width: map-get($breakpoints, $breakpoint)) {
					@content;
				}
			}
		}

	//
	// Button Colours
	//
	
		@mixin buttonColour($background-color, $color){
			background-color: $background-color;
			color: $color;
			&:hover {
				color: $color;
				background-color: darken($background-color, 10%);
			}
			&:active {
				color: $color;
				background-color: darken($background-color, 15%);
			}
			&:focus {
				color: $color;
				border-color: darken($background-color, 25%);
			}
		}
		
	//
	// Main Offset Panel
	//
	
		@mixin mainOffsetPanel(){
			// margin-top: $main-padding / 2;
			// margin-bottom: $main-padding / 2;
			@include breakpoint(medium) {
				margin-top: 0;
				margin-bottom: 0;	
			}
			&:before {
				content: '';
				position: absolute;
				width: calc(100% + #{$form-option-row-offset * 4});
				height: calc(100% + #{$form-option-row-offset * 2});
				left: -#{$form-option-row-offset * 2};
				top: -#{$form-option-row-offset};
				@include breakpoint(medium) {
					width: 100%;
					height: 100%;
					left: 0;
					top: 0;	
				}
			}
			
		}