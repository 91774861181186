// ===============================
//
// Settings
//
// ===============================

	//
	// Breakpoints
	//

		$breakpoints: (
		  small: 640px,
		  medium:  1024px,
		  large: 1200px,
		  xlarge: 1440px
		);

	//
	// Colours
	//

		$colours: (
			// Brand
			primary: #1f1c1c,
			secondary: #484849,
			// Neutrals
			white: #ffffff,
			light-grey: #f9f9f9,
			medium-grey: #bdbdbd,
			medium-dark-grey: #A0A0A0,
			dark-grey: #414042,
			// Typography
			body: lighten(#000, 20%),
			heading: #000,
			// States
			error: #bb4b46,
			warning: #e0d153,
			info: #57c7c1,
			success: #56d362,
			// Status
			status-green: #27AE60,
			status-orange: #F2842D,
			status-red: #D0021B,
			status-blue: #0D99F2,
			status-yellow: #F1C40E,
			status-pink: #FF50F2,
			status-purple: #9B59B6,
			status-turquoise: #2CE0BD,
			status-light: #CCD1D6,
			status-grey: #98A3AE,
			status-black: #32475E
		);

	//
	// Fonts
	//

		// Include any @imports to CDN files e.g. Google Fonts
		@import url('https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900');

	//
	// Base
	//

		$base-width: 		960px;
		$base-padding: 		20px;
		$base-margin: 		20px;
		$base-transition: 	all 0.35s ease;
		$base-font-size: 	16px;
		$base-line-height:	1.5;
		$base-border-radius: 0px;

	//
	// Body
	//

		$body-font-family: "gnuolane", "Helvetica Neue", Helvetica, Arial, sans-serif;
		$body-background-color: colour(light-grey);
		$body-color: colour(body);

	//
	// Section
	//

		$section-background-color: colour(light-grey);

	//
	// Heading
	//

		$heading-font-family: "gnuolane", "Helvetica Neue", Helvetica, Arial, sans-serif;
		$heading-font-family-condensed: "gnuolane", Helvetica, Arial, sans-serif;
		$heading-font-weight: 500;
		$heading-text-transform: uppercase;
		$heading-letter-spacing: 0;
		$heading-color: colour(heading);

	//
	// Links
	//

		$link-color: darken($body-color, 20%);
		$link-text-decoration: underline;

		$link-edit-color: colour(medium-dark-grey);

	//
	// Header & Footer
	//

		$header-background-color: #FFF;
		$header-border-color: colour(medium-grey);

		$footer-background-color: colour(medium-grey);
		$footer-color: colour(dark-grey);
		$footer-link-color: colour(dark-grey);

	//
	// Section
	//

		$section-padding: 70px;

	//
	// Main
	//

		$main-margin: $base-margin;
		$main-padding: $base-padding * 1.5;

		$main-background-color: #FFF;
		$main-width: $base-width - $main-margin;

	//
	// Sidebar
	//

		$sidebar-padding: $base-padding * 4;
		$sidebar-border-color: colour(medium-grey);
		$sidebar-highlighted-background-colour: #FFF;
		$sidebar-highlighted-colour: colour(heading);

		$sidebar-navigation-link-color: $link-color;
		$sidebar-navigation-link-hover-color: darken($link-color, 10%);
		$sidebar-navigation-link-active-color: colour(primary);

	//
	// Breadcrumbs
	//

		$breadcrumb-border-color: colour(medium-grey);
		$breadcrumb-highlight-color: colour(primary);

	//
	// Forms
	//

		$form-label-color: colour(dark-grey);

		$form-label-small-color: colour(medium-dark-grey);
		$form-label-small-font-weight: 600;
		$form-label-small-font-size: $base-font-size - 2;

		$form-input-border-color: colour(medium-grey);
		$form-input-background-color: #FBFBFB;
		$form-input-padding: $base-padding / 2;
		$form-input-border-radius: $base-border-radius;
		$form-input-box-shadow: none;
		$form-input-color: $body-color;

		$form-input-autocomplete-background-color: lighten(colour(warning), 45%);

		$form-option-color: colour(medium-dark-grey);
		$form-option-checked-color: colour(primary);

		$form-option-row-border-color: colour(medium-grey);
		$form-option-row-background-colour: colour(light-grey);
		$form-option-row-color: lighten($body-color, 20%);
		$form-option-row-border-radius: $base-border-radius;
		$form-option-row-offset: 15px;

		$form-set-group-border-color: colour(white);

		$form-placeholder-color: darken($form-input-background-color, 20%);

		$input-font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;

	//
	// Validation
	//

		$validation-alert-padding: 7px;
		$validation-alert-margin-top: 0;
		$validation-alert-font-size: 12px;

		$validation-alert-error-background-color: colour(error);
		$validation-alert-error-color: white;

		$validation-field-error-border-color: colour(error);


	//
	// Buttons
	//

		$button-primary-color: #FFF;
		$button-primary-background-color: colour(primary);

		$button-secondary-color: #FFF;
		$button-secondary-background-color: colour(secondary);

		$button-border-radius: $base-border-radius;
		$button-text-color: $link-color;
		$button-text-transform: uppercase;
		$button-letter-spacing: 0;
		$button-text-align: left;

		$button-action-color: colour(medium-grey);

	//
	// Box
	//

		$box-background-color: colour(light-grey);
		$box-border-color: colour(medium-grey);

	//
	// Icons
	//

		$icon-color: colour(dark-grey);

		$icon-round-border-color: colour(dark-grey);
		$icon-round-border-color-hover: colour(primary);
		$icon-round-color-hover: colour(primary);

		$icon-round-font-size: $base-font-size + 6;

	//
	// Loader
	//

		$loader-spinner-background-colour: colour(primary);
		$loader-spinner-track-background-color: rgba(255, 255, 255, 1);

		$loader-spinner-border-width: 7px;
		$loader-spinner-width: 70px;

		$loader-overlay-background-color: rgba($body-background-color, 0.8);

	//
	// Notification
	//

		$notification-font-size: 15px;
		$notification-padding: 15px;
		$notification-text-align: center;

	//
	// Alerts
	//

		$alert-font-size: 13px;
		$alert-padding: 12px;
		$alert-text-align: left;

	//
	// Address
	//

		$address-padding: $base-padding;
		$address-border-size: 1px;
		$address-border-color: colour(light-grey);

	//
	// Order
	//

		$order-timeline-bar-height: 6px;
		$order-timeline-bar-active-background-color: colour(medium-grey);
