// ===============================
//
// Button
//
// ===============================

	:root {
		.commerce {

			//
			// Base
			//

				.button {
					@include buttonColour($button-primary-background-color, $button-primary-color);
					transition: $base-transition;
					display: inline-block;
					vertical-align: middle;
					border: none;
					border-radius: $button-border-radius;
					font-size: $base-font-size;
					padding: $base-padding / 2 $base-padding;
					cursor: pointer;
					border: 1px solid transparent;
					font-size: $base-font-size;
					font-weight: 500;
					text-decoration: none;
					font-family: $heading-font-family;
					text-transform: $button-text-transform;
					margin: 0;
					line-height: $base-line-height;
					letter-spacing: $button-letter-spacing;
					text-align: $button-text-align;
					appearance: none;
					&:hover,
					&:focus,
					&:active {
						outline: none;
						box-shadow: none;
					}
					@include breakpoint(small) {
						width: 100%;
						text-align: center;
						font-size: $base-font-size - 1;
					}
					&:before,
					&:after {
						display: none;
					}
					.fa {
						margin-right: $base-margin / 3;
					}
					&:disabled {
						opacity: 0.2;
						cursor: not-allowed;
					}
					&.colour-secondary {
						@include buttonColour($button-secondary-background-color, $button-secondary-color);
					}
					&.size-large {
						padding: $base-padding / 1.5 $base-padding * 1.5;
						font-size: $base-font-size + 1;
						@include breakpoint(small) {
							padding: $base-padding / 1.6 $base-padding * 1.2;
							font-size: $base-font-size;
						}
					}
					&.size-small {
						font-size: $base-font-size - 1;
						padding: $base-padding / 2.5 $base-padding / 1.2;
					}
					&.type-text {
						background: none;
						color: $button-text-color;
						padding: 0;
						border: none;
						border-bottom: 1px solid transparent;
						outline: none;
						&:hover {
							color: darken($button-text-color, 10%);
						}
						&:active {
							color: lighten($button-text-color, 15%);
						}
						&:focus {
							border-bottom-style: dotted;
							border-bottom-color: $button-text-color;
						}
					}
					&.type-action {
						@extend .button.type-text;
						color: $button-action-color;
						&:hover {
							color: darken($button-action-color, 10%);
						}
						&:active {
							color: lighten($button-action-color, 15%);
						}
						&:focus {
							border-bottom-color: $button-action-color;
						}
					}
				}

			//
			// Group
			//

				.button-group {
					display: inline-block;
					vertical-align: top;
					width: 100%;
					.button {
						margin-right: $base-margin / 1.5;
						@include breakpoint(small) {
							margin-right: 0;
							margin-bottom: $base-margin / 1.5;
						}
					}
					&.type-align-right {
						.button-group-container {
							float: right;
							@include breakpoint(small) {
								float: none;
								display: flex;
								flex-wrap: wrap;
							}
							.button {
								@include breakpoint(small) {
									flex: 0 0 100%;
									order: 1;
									margin-bottom: $base-margin / 1.5;
									&:first-child {
										order: 2;
										margin-bottom: 0;
									}
									&:last-child {
										margin-bottom: $base-margin / 1.5;
									}
								}
							}
						}
						.button {
							&:last-child {
								margin-right: 0;
								margin-bottom: 0;
							}
						}
					}
				}

			//
			// Misc Buttons
			//

				.button-remove {
					transition: $base-transition;
					cursor: pointer;
					background: none;
					border: none;
					color: colour(error);
					font-size: $base-font-size + 2;
					padding: $base-padding / 2;
					&:hover {
						color: darken(colour(error), 10%);
					}
					&:active {
						color: darken(colour(error), 10%);
					}
				}

		}
	}
