// ===============================
//
// Footer
//
// ===============================

	:root {
		.commerce {

			.app-footer {
				margin: {
					top: $base-margin;
				}
				border: {
					top: 1px solid $footer-background-color;
				}
				color: $footer-color;
				padding: $base-padding * 1.5 0;
				font-size: $base-font-size - 1;
				text-align: center;
			}
			
			.footer-navigation {
				margin-bottom: $base-margin;
				a {
					color: $footer-link-color;
					&:hover {
						color: darken($footer-link-color, 20%);
					}
					&:active {
						color: darken($footer-link-color, 30%);
					}
					&:focus {
						border-bottom-color: $footer-link-color;
					}
				}
			}
	
		}
	}