// ===============================
//
// Base
//
// ===============================

	//
	// Misc
	//

		* {
			box-sizing: border-box;
		}

	//
	// Body
	//
		html,
		body {
			width: 100%;
			height: 100%;
			background-color: $body-background-color !important;
		}

	//
	// Other Base Styles
	//

	:root {
		.commerce {

			background-color: $section-background-color;
			font-size: $base-font-size;
			line-height: $base-line-height;
			font-family: $body-font-family;
			color: $body-color;

			//
			// Images
			//

				img {
					display: block;
					max-width: 100%;
				}

			//
			// Links
			//

				a {
					color: $link-color;
					transition: $base-transition;
					border-bottom: 1px solid transparent;
					text-decoration: $link-text-decoration;
					outline: none;
					&:hover {
						color: darken($link-color, 20%);
					}
					&:active {
						color: darken($link-color, 30%);
					}
					&:focus {
						border-bottom-style: dotted;
						border-bottom-color: $link-color;
					}
					&.type-no-text-decoration {
						text-decoration: none;
					}
					&.type-edit {
						@extend a.type-no-text-decoration;
						font-family: $body-font-family;
						font-weight: 400;
						font-size: $base-font-size - 1;
						margin-left: $base-margin;
						color: $link-edit-color;
						&:hover {
							color: darken($link-edit-color, 20%);
						}
						&:active {
							color: darken($link-edit-color, 30%);
						}
						.icon {
							display: inline-block;
							vertical-align: middle;
							font-size: $base-font-size + 3;
							margin-right: $base-margin / 4;
						}
					}
				}

			//
			// Typography
			//

				p {
					margin-top: 0;
					color: $body-color;
					&.type-size-large {
						font-size: $base-font-size + 2;
					}
				}

			//
			// Headings
			//

				h1,h2,h3,h4,h5,h6 {
					transition: $base-transition;
					font-family: $heading-font-family;
					font-weight: $heading-font-weight;
					text-transform: $heading-text-transform;
					letter-spacing: $heading-letter-spacing;
					color: $heading-color;
					margin: 0;
					padding: 0;
					margin-bottom: $base-margin;
					@include breakpoint(medium) {
						margin-bottom: $base-margin / 1.2;
					}
				}

				h1 {
					font-family: $heading-font-family-condensed;
					font-weight: 600;
				}

				h2 {
					font-size: $base-font-size * 1.2;
					@include breakpoint(medium) {
						font-size: $base-font-size * 1.1;
					}
				}

				h3 {
					font-size: $base-font-size * 1;
					@include breakpoint(small) {
						font-size: $base-font-size / 1.1;
					}
				}

				h4 {
					font-size: $base-font-size / 1.18;
				}

			//
			// Horizontal Rule
			//

				hr,
				.hr {
					border: none;
					border-bottom: 1px solid $form-set-group-border-color;
					margin: $base-margin 0;
					@include breakpoint(small) {
						margin: $base-margin / 2 0;
					}
				}

			//
			// Sections
			//

				.section,
				.section-container {
					display: inline-block;
					vertical-align: top;
					width: 100%;
				}

				.section-container {
					display: block;
					overflow: hidden;
					padding: {
						top: $base-padding * 1.5;
						right: percentage($section-padding / $base-width);
						bottom: $base-padding * 1.5;
						left: percentage($section-padding / $base-width);
					};
					margin: {
						left: auto;
						right: auto;
					}
					&.padding-y-none {
						padding: {
							top: 0;
							bottom: 0;
						}
					}
					@include breakpoint(medium) {
						padding: {
							top: $base-padding / 1.2;
							right: percentage(($section-padding / 1.2) / $base-width);
							bottom: $base-padding / 1.2;
							left: percentage(($section-padding / 1.2) / $base-width);
						}
					}
				}

			//
			// Main
			//

				.main {
					position: relative;
					float:left;
					width: percentage((($main-width / 12) * 8.5) / $base-width);
					margin-right: percentage($main-margin / $base-width);
					&.type-sidebar-left {
						margin-right: 0;
						margin-left: percentage($main-margin / $base-width);
						@include breakpoint(medium) {
							margin-right: 0;
							margin-left: 0;
						}
					}
					&.type-sidebar-none {
						left: 50%;
						transform: translateX(-50%);
					}
					@include breakpoint(medium) {
						width: 100%;
						margin-right: 0;
					}
				}

				.main-container {
					background-color: $main-background-color;
					padding: $main-padding;
					&.type-padding-smaller-top {
						padding: {
							top: $main-padding / 2
						}
					}
					@include breakpoint(medium) {
						padding: $main-padding / 1.5;
					}
					@include breakpoint(small) {
						padding: $main-padding / 2.5;
					}
				}

				.main-footer {
					padding: $main-padding / 2 $main-padding 0;
					@include breakpoint(medium) {
						padding-left: 0;
						padding-right: 0;
					}
				}

			//
			// Sidebar
			//

				.sidebar {
					float:left;
					border: none;
					width: percentage((($main-width / 12) * 3.5) / $base-width);
					p {
						font-size: $base-font-size / 1.1;
					}
					@include breakpoint(medium) {
						width: 100%;
						.button {
							display: none;
						}
					}
				}

				.sidebar-container {
					border-bottom: 1px solid $sidebar-border-color;
					padding: $base-padding 0;
					margin-bottom: $base-margin;
				}

				.sidebar-padding-x {
					padding-right: percentage($sidebar-padding / $base-width);
					padding-left: percentage($sidebar-padding / $base-width);
					@include breakpoint(small) {
						padding-right: $base-padding / 2;
						padding-left: $base-padding / 2;
					}
				}

			//
			// Columns
			//

				.row {
					display: inline-block;
					vertical-align: top;
					width: 100%;
					margin-bottom: $base-margin;
				}

				.column-equal {
					float:left;
					&:first-child {
						width: calc(50% - #{$base-margin});
						margin-right: $base-margin;
					}
					&:last-child {
						width: 50%;
					}
					@include breakpoint(small) {
						&:first-child,
						&:last-child {
							width: 100%;
							margin-right: 0;
						}
						&:first-child {
							margin-bottom: $base-margin / 2;
						}
					}
				}

			//
			// Icons
			//

				.icon {
					transition: $base-transition;
					color: $icon-color;
				}

			//
			// Boxes
			//

				.box {
					padding: $base-padding;
					display: block;
					background-color: $box-background-color;
					border: 1px solid $box-border-color;
					width: 100%;
					font-family: $heading-font-family;
					color: $heading-color;
					small {
						font-size: $base-font-size + 2;
					}
					strong {
						display: block;
						font-size: $base-font-size + 8;
					}
				}

			//
			// Loader
			//

				.loader-container {
					transition: $base-transition;
					overflow: hidden;
					position: absolute;
					width: 100%;
					height: 100%;
					background-color: $loader-overlay-background-color;
					opacity: 0;
					z-index: -10;
					&.is-visible {
						opacity: 1;
						z-index: 99;
					}
				}

				.loader {
					border-radius: 50%;
					width: $loader-spinner-width;
					height: $loader-spinner-width;
				}

				.loader {
					margin: $base-margin * 3 auto;
					font-size: 10px;
					position: relative;
					text-indent: -9999px;
					border-top: $loader-spinner-border-width solid $loader-spinner-track-background-color;
					border-right: $loader-spinner-border-width solid $loader-spinner-track-background-color;
					border-bottom: $loader-spinner-border-width solid $loader-spinner-track-background-color;
					z-index: 100;
					border-left: $loader-spinner-border-width solid $loader-spinner-background-colour;
					transform: translateZ(0);
					animation: loader 1.1s infinite linear;
				}

				@keyframes loader {
					0% {
						transform: rotate(0deg);
					}
					100% {
						transform: rotate(360deg);
					}
				}

			//
			// Notification & Alerts
			//

				.alert,
				.notifications {
					background-color: colour(success);
				}

				.alert {
					text-align: $alert-text-align;
					padding: $alert-padding;
					color: #FFF;
 					font-size: $alert-font-size + 1;
				}

				.notifications {
					text-align: $notification-text-align;
					padding: $notification-padding;
					color: #FFF;
					font-size: $notification-font-size;
				}

			//
			// Misc
			//

				.not-important {
					display: inline-block;
					vertical-align: top;
					width: 100%;
					color: rgba($body-color, 0.5);
					font-size: $base-font-size - 1;
				}

			//
			// Helpers
			//

				.align-center {
					text-align: center;
				}

				.margin-bottom-0 {
					margin-bottom: 0;
				}

			//
			// States
			//

				.is-hidden {
					display: none;
				}

			//
			// Colours
			//

			@each $colour, $value in $colours {

				.colour-#{$colour} {
					color: #{$value};
				 }

				.colour-background-#{$colour} {
					background-color: #{$value};
				}

				.colour-border-#{$colour} {
					border-color: #{$value};
				}

				.colour-border-top-#{$colour} {
					border-top-color: #{$value};
				}

				.colour-border-right-#{$colour} {
					border-right-color: #{$value};
				}

				.colour-border-bottom-#{$colour} {
					border-bottom-color: #{$value};
				}

				.colour-border-left-#{$colour} {
					 border-left-color: #{$value};
				}

			}

		}
	}
