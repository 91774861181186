// ===============================
//
// Modules
//
// ===============================

	:root {

		//
		// Swal
		//


			.swal {
				&-modal {
					padding: $base-padding;
				}
				&-title {
					margin-bottom: 0;
				}
				&-footer {
					text-align: center;
				}
			}

		.commerce {

			//
			// Products
			//

				.product-content-container {
					display: flex;
					align-content: center;
					align-items: center;
				}

				.product-image {
					margin-right: $base-margin;
					max-width: 100px;
				}

				.product-title {
					margin-bottom: 0;
				}

				.product-price {
					display: block;
					margin-bottom: $base-margin / 3;
					text-align: left;
					font-size: $base-font-size + 2;
					@include breakpoint(small) {
						text-align: center;
					}
				}

				.product-options {
					font-size: $base-font-size - 1;
					li {
						margin-right: $base-margin / 5;
						&:last-child {
							margin-right: 0;
						}
					}
					span {
						color: lighten($body-color, 20%);
					}
				}

				.product-quantity {
					margin-bottom: $base-margin / 5;
				}

			//
			// Address
			//

				.address-container {
					border: $address-border-size solid $address-border-color;
					padding: $address-padding;
					position: relative;
				}

				.address-content {
					width: 80%;
				}

				.address-actions {
					position: absolute;
					top: $address-padding;
					right: $address-padding;
					.button {
						font-size: $base-font-size - 2;
					}
				}

			//
			// Orders
			//

				.order-timeline {
					width: 100%;
					display: flex;
					flex-wrap: nowrap;
					margin-bottom: $base-margin;
					@include breakpoint(small) {
						margin-bottom: $base-margin / 2;
					}
				}

				.order-timeline-item {
					flex-grow: 1;
					position: relative;
					text-align: center;
					font-size: $base-font-size - 2;
					@include breakpoint(small) {
						display: none;
					}
					&:first-child {
						.order-timeline-bar {
							border-radius: $order-timeline-bar-height 0 0 $order-timeline-bar-height;
							@include breakpoint(small) {
								border-radius: $order-timeline-bar-height;
							}
						}
					}
					&:last-child {
						.order-timeline-bar {
							border-radius: 0 $order-timeline-bar-height $order-timeline-bar-height 0;
							@include breakpoint(small) {
								border-radius: $order-timeline-bar-height;
							}
						}
					}
					&:only-child {
						.order-timeline-bar {
							border-radius: $order-timeline-bar-height;
						}
					}
					@each $colour, $value in $colours {
						&.#{$colour} {
							color: #{$value};
							.order-timeline-bar {
								background-color: #{$value};
								&:before {
									background-color: #{$value};
								}
								&:after {
									border-color: #{$value};
								}
							}
						}
						@include breakpoint(small) {
						&.#{$colour} {
							color: #{$value} !important;
							.order-timeline-bar {
								background-color: #{$value} !important;
								&:before {
									background-color: #{$value} !important;
								}
								&:after {
									border-color: #{$value} !important;
								}
							}
						}
						}
					}
					&.is-active {
						color: $order-timeline-bar-active-background-color;
						display: block;
						@include breakpoint(small) {
							text-align: left;
						}
						.order-timeline-bar {
							background-color: $order-timeline-bar-active-background-color;
							&:before,
							&:after {
								display: block;
								@include breakpoint(small) {
									display: none;
								}
							}
							&:before {
								background-color: $order-timeline-bar-active-background-color;
							}
							&:after {
								border-color: $order-timeline-bar-active-background-color;
							}
						}
					}
				}

				.order-timeline-bar {
					display: block;
					position: relative;
					width: calc(100% - 1px);
					height: $order-timeline-bar-height;
					margin-bottom: $base-margin / 3;
					&:before,
					&:after {
						display: none;
						content: '';
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
						border-radius: 100%;
					}
					&:before {
						width: $order-timeline-bar-height * 2;
						height: $order-timeline-bar-height * 2;
						z-index: 1;
						background-color: $order-timeline-bar-active-background-color;
					}
					&:after {
						border: 1px solid $order-timeline-bar-active-background-color;
						width: $order-timeline-bar-height * 3;
						height: $order-timeline-bar-height * 3;
						background-color: $main-background-color;
					}
				}

				.order-status {
					font-size: $base-font-size - 1;
					border-width: 1px;
					border-style: solid;
					border-radius: 2px;
					padding: $base-padding / 3 $base-padding / 2;
				}

				.order-table {
					margin-bottom: $base-margin / 3;
				}

				.order-totals {
					@extend .list-blank;
					margin-bottom: $base-margin;
					li {
						display: flex;
						justify-content: space-between;
						font-size: $base-font-size - 1;
						margin-bottom: $base-margin / 3;
						&:last-child {
							font-weight: 600;
							font-size: $base-font-size + 1;
						}
					}
				}

		}
	}
