// ===============================
//
// Navigation
//
// ===============================

	:root {
		.commerce {

			//
			// Blank
			//
			
				.navigation-blank {
					ul {
						@extend .list-blank;
					}
				}
				
			//
			// Inline
			//
			
				.navigation-inline {
					@extend .navigation-blank;
					ul {
						@extend .list-inline;
					}
					li {
						margin-right: $base-margin;
						&:last-child {
							margin-right: 0;
						}
					}
				}
				
			//
			// Sidebar
			//
			
				.navigation-sidebar {
					@extend .navigation-blank;
					li {
						margin-bottom: 5px;
						&:last-child {
							margin-bottom: 0;
						}
						&.is-active {
							a {
								font-weight: 600;
								color: $sidebar-navigation-link-active-color;
							}	
						}
						@include breakpoint(medium) {
							display: inline-block;
							vertical-align: middle;
							margin-bottom: 0;
							margin-right: $base-margin / 2;
						}
						@include breakpoint(small) {
							display: block;
							width: auto;
						}
					}
					a {
						display: inline-block;
						vertical-align: top;
						text-decoration: none;
						transform: translateX(0);
						color: $sidebar-navigation-link-color;
						&:hover,
						&:active {
							color: $sidebar-navigation-link-hover-color;
							transform: translateX(3px);
						}
					}
				}
		
			//
			// Breadcrumbs
			//
			
				.navigation-breadcrumbs-steps {
					width: 100%;
					display: none;
					margin-bottom: $base-margin / 4;
					font-size: $base-font-size + 1;
					@include breakpoint(medium) {
						display: inline-block;
						vertical-align: top;
					}
					strong {
						float: left;
						color: $breadcrumb-highlight-color;
					}
					p {
						float: right;
						margin: 0;
						color: $breadcrumb-border-color;
					}
					span {
						color: $body-color;
					}
				}
			
				.navigation-breadcrumbs {
					@extend .navigation-blank;
					@extend .list-inline;
					font-family: $heading-font-family;
					margin-bottom: $base-margin * 1.8;
					@include breakpoint(medium) {
						margin-bottom: $base-margin;
					}
					ul {
						display: flex;
						justify-content: space-between;
						border-top: 1px solid $breadcrumb-border-color;
						@include breakpoint(medium) {
							border: none;
							justify-content: flex-start;
						}
					}
					li {
						position: relative;
						&:before {
							content: '';
							position: absolute;
							top: 0;
							width: 50%;
							height: 1px;
							background: #FFF;
							transform: translateY(-100%);
							display: none;
						}
						&:first-child {
							&:before {
								display: block;
								left: 0;	
							}
						}
						&:last-child {
							&:before {
								display: block;
								right: 0;	
							}
						}
						@include breakpoint(medium) {
							flex-grow: 1;
							flex-basis: 0;	
						}
					}
					a {
						transition: color 0.5s ease;
						position: relative;
						display: block;
						color: $breadcrumb-border-color;
						text-decoration: none;
						font-size: $base-font-size - 1;
						padding-top: $base-padding / 2;
						&:before,
						&:after {
							content: '';
							position: absolute;
							top: 0;
							left: 50%;
							transform: translate(-50%, -50%);
							width: 17px;
							height: 17px;
							border-radius: 100%;
							transition: $base-transition;
						}
						&:after {
							opacity: 0;
							border: 1px solid $breadcrumb-border-color;
							background-color: #fff;
						}
						&:before {
							width: 8px;
							height: 8px;
							background-color: $breadcrumb-border-color;
							z-index: 1;
						}
						&.is-active,
						&:hover.is-active,
						&:active.is-active {
							color: $breadcrumb-highlight-color;
							font-weight: 600;
							&:before {
								background-color: $breadcrumb-highlight-color;
							}
							&:after {
								opacity: 1;
								border-color: $breadcrumb-highlight-color;
							}
						}
						&:hover,
						&:active {
							color: $body-color;
							&:before {
								background-color: $body-color;
							}
							&:after {
								opacity: 1;
								border-color: $body-color;
								width: 11px;
								height: 11px;
							}
						}
						@include breakpoint(medium) {
							padding-top: 0;
							text-indent: -9999px;
							border-bottom: 1px solid $breadcrumb-border-color;
							height: 4px;
							&:before,
							&:after {
								display: none;
							}
							&.is-active,
							&:hover.is-active,
							&:active.is-active {
								display: block;
								background-color: $breadcrumb-highlight-color;
								border-bottom-color: $breadcrumb-highlight-color;
							}		
						}
					}
				}
				
		}
	}