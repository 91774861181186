// ===============================
//
// Header
//
// ===============================

	:root {
		.commerce {

			.app-header {
				background-color: $header-background-color;
				padding: $base-padding 0;
				text-align: center;
				a {
					display: inline-block;
					margin: auto;
				}
			}
			
		}
	}