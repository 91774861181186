// ===============================
//
// Table
//
// ===============================

	:root {
		.commerce {

			table {
				width: 100%;
				text-align: left;
				padding: 0;
				margin: 0;
				border-spacing: 0;
				margin-bottom: $base-margin;
				border-radius: 0;
				thead {
					@include breakpoint(small) {
						display: none;
					}
				}
				thead,
				tbody {
					background: none;
					border: none;
				}
				thead {
					color: $body-color;
					@include breakpoint(small) {
						display: none;
					}
				}
				tr {
					&:nth-child(even),
					&:nth-child(odd) {
						background: none;
					}
					@include breakpoint(small) {
						display: block;
						width: 100%;
						float: left;
						border: 2px solid colour(light-grey);
						margin-bottom: $base-margin;	
					}
				}
				th,
				td {
					border-bottom: 1px solid #eee;
					text-align: left;
					&:last-child {
						text-align: right;
						@include breakpoint(small) {
							text-align: center;
						}
					}
				}
				th {
					padding: $base-padding / 3 $base-padding;
					font-size: $base-font-size - 1;
					font-weight: 500;
				}
				td {
					padding: $base-padding / 2 $base-padding;
					@include breakpoint(small) {
						width: 100%;	
						float: left;
						text-align: center;
						.product-image {
							display: none;
							margin: auto;
							margin-bottom: $base-margin;
						}
						.product-content {
							width: 100%;
						}
						.product-content-container {
							display: block;
						}
					}	
				}
				th,
				td {
					&:first-child {
						padding-left: 0;
						@include breakpoint(medium) {
							padding: $base-padding / 1.3 $base-padding;	
						}
					}
					&:last-child {
						padding-right: 0;
						@include breakpoint(medium) {
							padding-left: 0;
						}
					}
				}
			}
	
		}
	}