// ===============================
//
// Lists
//
// ===============================

	:root {
		.commerce {
			
			//
			// Blank
			//
			
				.list-blank {
					list-style: none;
					padding: 0;
					margin: 0;
				}
				
			//
			// Inline
			//
			
				.list-inline {
					@extend .list-blank;
					li {
						display: inline-block;
						vertical-align: middle;
					}
				}
		
			//
			// Values
			//
			
				.list-values {
					@extend .list-blank;
					li {
						font-size: $base-font-size - 1;
						padding: 0;
						margin-bottom: $base-margin / 4;
						display: flex;
						align-items: center;
						&.type-highlighted {
							overflow: auto;
							background-color: $sidebar-highlighted-background-colour;
							color: $sidebar-highlighted-colour;
							margin-top: $base-margin;
							padding: {
								top: $base-padding / 1.5;
								bottom: $base-padding / 1.5;
							}
							span {
								font-size: $base-font-size * 1.1;
							}
							strong {
								font-size: $base-font-size * 1.4;
								font-weight: 600;
							}
						}
					}
					span,
					strong {
		
					}
					span {
						width: 70%;
					}
					strong {
						font-weight: 500;
					}
					&.type-sidebar {
						margin-bottom: $base-margin / 2;
						li {
							@extend .sidebar-padding-x;	
						}
					}
				}
				
			//
			// Icons
			// 
			
				.list-icon {
					@extend .list-inline;
					margin-top: $base-margin;
					li {
						margin-right: $base-margin / 2;
						width: 40px;
						height: 40px;
					}
					a {
						display: block;
						width: 100%;
						height: 100%;
						border: 1px solid $icon-round-border-color;
						border-radius: 100%;
						font-size: $icon-round-font-size;
						line-height: 1;
						text-align: center;
						&:hover {
							transform: scale(0.9);
							border-color: $icon-round-border-color-hover;
							.icon {
								color: $icon-round-color-hover;
							}
						}
					}
					.icon {
						position: relative;
						top: 50%;
						transform: translateY(-50%);
					}
				}
				
			//
			// Cards
			//
			
				.list-cards {
					@extend .list-inline;
					margin-bottom: $base-margin;
					li {
						margin-right: 3px;
					}
					img {
						max-width: 40px;
					}
				}
		
		}
	}