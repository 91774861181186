// ===============================
//
// Form
//
// ===============================

	:root {
		.commerce {

			//
			// Placeholder
			//

				::-webkit-input-placeholder {
					color: $form-placeholder-color;
				}

				::-moz-placeholder {
					color: $form-placeholder-color;
				}

				:-ms-input-placeholder {
					color: $form-placeholder-color;
				}

				:-moz-placeholder {
					color: $form-placeholder-color;
				}

			//
			// Inputs
			//

				input,
				select,
				button,
				checkbox,
				textarea,
				radio {
					font-family: $input-font-family;
					outline: none;
				}

				input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]),
				textarea,
				select {
					appearance: none;
					height: auto;
					width: 100%;
					margin: 0;
					transition: $base-transition;
					background-color: $form-input-background-color;
					border: 1px solid $form-input-border-color;
					padding: $form-input-padding;
					border-radius: $form-input-border-radius;
					font-size: $base-font-size - 2;
					box-shadow: $form-input-box-shadow;
					color: $form-input-color;
					text-transform: none;
					letter-spacing: -0.2px;
					font-weight: 400;
					@include breakpoint(small) {
						padding: $form-input-padding / 1.2;
					}
					&:-webkit-autofill {
						box-shadow: 0 0 0px 1000px $form-input-autocomplete-background-color inset;
					}
					&:hover,
					&:focus {
						&:-webkit-autofill {
							box-shadow: 0 0 0px 1000px $form-input-autocomplete-background-color inset;
						}
					}
					&:hover {
						border-color: darken($form-input-border-color, 20%);
					}
					&:focus {
						box-shadow: 0px 0px 10px rgba(#000, 0.2);
						outline: none;
						border-color: darken($form-input-border-color, 10%);
						background-color: #FFF;
					}
				}

				select {

				}

				textarea {
					height: 100px;
					min-height: 0;
					text-transform: none;
				}

			//
			// Radio / Checkbox (Options)
			//

				.field-option,
				.field-option-row {
					cursor: pointer;
					input[type='checkbox'],
					input[type='radio'] {
						display: none;
						&:checked + .option-custom {
							border-color: $form-option-checked-color;
							&:before {
								background-color: $form-option-checked-color;
								opacity: 1;
							}
						}
						&:checked + .option-custom + span {
							color: $body-color;
						}
					}
					input[type='checkbox'],
					input[type='radio'],
					.option-custom {
						margin-right: $base-margin / 5;
					}
				}

				.field-option {
					margin-bottom: 0;
					color: $form-option-color;
					cursor: pointer;
					input[type='checkbox'],
					input[type='radio'] {
						&:checked + .option-custom + span {
							color: $body-color;
						}
					}
					span {
						transition: $base-transition;
						display: inline-block;
						vertical-align: middle;
					}
					&:hover {
						.option-custom {
							border-color: darken($form-option-color, 30%);
							transform: scale(1.1);
						}
						span {
							color: darken($form-option-color, 30%);
						}
					}
				}

				.field-option-row {
					position: relative;
					z-index: 1;
					display: flex;
					align-items: center;
					padding: $base-padding / 2 $base-padding / 2;
					color: $form-option-row-color;
					margin-bottom: -1px;
					@include breakpoint(medium) {
						align-items: inherit;
						padding: $base-padding / 1.5;
						margin-bottom: 0;
					}
					@include breakpoint(small) {
						padding: $base-padding / 1.7;
					}
					&:before {
						transition: $base-transition;
						content: '';
						position: absolute;
						width: calc(100% + #{($form-option-row-offset * 2)});
						// height: calc(100% + #{($form-option-row-offset * 2)});
						height: 100%;
						left: -#{$form-option-row-offset};
						// top: -#{$form-option-row-offset};
						top: 0;
						border-radius: $form-option-row-border-radius;
						border: 1px solid transparent;
						z-index: 0;
						@include breakpoint(medium) {
							width: 100%;
							height: 100%;
							left: 0;
							top: 0;
						}
					}
					.option-custom,
					.option-content,
					.option-value {
						transition: $base-transition;
						position: relative;
						z-index: 1;
						margin-right: $base-margin / 2;
					}
					.option-custom {
						flex-shrink: 0;
						@include breakpoint(medium) {
							top: 5px;
						}
					}
					.option-content {
						font-size: $base-font-size - 1;
						width: 85%;
						display: flex;
						align-items: center;
						@include breakpoint(small) {
							width: 55%;
						}
						p {
							&:last-of-type {
								margin-bottom: 0;
							}
						}
					}
					.option-content-image {
						@include breakpoint(small) {
							width: 100%;
						}
					}
					.option-value {
						margin-left: auto;
						font-weight: 600;
					}
					.option-value-image {
						width: 6%;
						@include breakpoint(small) {
							display: none;
						}
					}
					h3 {
						margin-bottom: 0;
						color: $form-option-row-color;
					}
					&:hover {
						z-index: 10;
					}
					&:hover,
					&.is-checked {
						@extend .field-option:hover;
						color: $body-color;
						h3 {
							color: $heading-color;
						}
					}
					&:hover,
					&.is-checked {
						&:before {
							border-color: $form-option-row-border-color;
						}
					}
					&.is-checked {
						&:before {
							border-color: darken($form-option-row-border-color, 40%);
							background-color: #FFF;
						}
					}
				}

				.option-column {
					float: left;
					width: 50%;
					@include breakpoint(small) {
						width: 100%;
					}
				}

				.field-option-row-options {
					position: relative;
					border-radius: $form-option-row-border-radius;
					@include mainOffsetPanel;
				}

				.option-custom {
					transition: $base-transition;
					position: relative;
					width: 18px;
					height: 18px;
					display: inline-block;
					vertical-align: middle;
					border-radius: 100%;
					border: 1px solid $form-option-color;
					background: #FFF;
					&:before {
						transition: $base-transition;
						content: '';
						position: absolute;
						top: 50%;
						left: 50%;
						width: 8px;
						height: 8px;
						background: $form-option-color;
						transform: translate(-50%,-50%);
						border-radius: 100%;
						opacity: 0;
					}
					&.type-radio {

					}
					&.type-checkbox {
						border-radius: 0;
						&:before {
							border-radius: 0;
						}
					}
				}

			//
			// Checkbox
			//

			//
			// Select
			//

			//
			// Labels
			//

				label {
					display: block;
					margin-bottom: $base-margin / 4;
					font-size: $base-font-size - 1;
					color: $form-label-color;
					small {
						color: $form-label-small-color;
						margin-left: $base-margin / 4;
						font-weight: $form-label-small-font-weight;
						font-size: $form-label-small-font-size;
					}
					@include breakpoint(small) {
						font-size: $base-font-size;
					}
				}

			//
			// Helper
			//

				.field-helper {
					@extend .not-important;
					display: block;
					margin-top: $base-margin / 5;
					font-size: $base-font-size - 1;
					text-align: left;
				}

			//
			// Extra
			//

				.field-extra {
					@extend .column-equal;
					p {
						font-size: $base-font-size - 2;
						color: lighten($body-color, 20%);
					}
				}

			//
			// Field Set
			//

				.field-set-blank {
					display: inline-block;
					vertical-align: top;
					width: 100%;
					margin-bottom: $base-margin * 1.5;
					@include breakpoint(medium) {
						margin-bottom: $base-margin;
					}
				}

				.field-set {
					@extend .field-set-blank;
					padding-bottom: $base-padding * 1.5;
					border-bottom: 1px solid $form-set-group-border-color;
					@include breakpoint(medium) {
						padding-bottom: $base-padding;
					}
					.field-grid {
						&:last-child {
							padding-bottom: 0;
						}
					}
					&:last-of-type,
					&.last {
						padding-bottom: 0;
						margin-bottom: 0;
						border: none;
					}
					&.type-inline {
						display: flex;
						.field-container {
							flex: 1;
							margin-right: $base-margin;
							margin-bottom: 0;
							@include breakpoint(small) {
								margin-right: 0;
								margin-bottom: $base-margin / 2;
							}
						}
						@include breakpoint(small) {
							display: block;
						}
					}
				}

			//
			// Field Container
			//

				.field-container {
					position: relative;
					display: inline-block;
					vertical-align: top;
					width: 100%;
					margin-bottom: $base-margin;
					@include breakpoint(small) {
						margin-bottom: $base-margin / 2;
					}
					&:last-child {
						margin-bottom: 0;
					}
					hr {
						margin-bottom: 0;
					}
					&.type-size-half {
						width: 50%;
						@include breakpoint(small) {
							width: 100%;
						}
					}
				}

			//
			// Grid
			//

				.field-grid {
					display: flex;
					flex-wrap: wrap;
					flex-flow: row wrap;
					justify-content: space-between;
					align-items: baseline;
					position: relative;
					width: 100%;
					padding-bottom: $base-margin;
					@include breakpoint(small) {
						display: inline-block;
						vertical-align: top;
					}
					.field-container {
						float:left;
						&:nth-last-child(-n+2):not(.type-size-full) {
							margin-bottom: 0;
							@include breakpoint(small) {
								margin-bottom: $base-margin / 2;
							}
						}
						&:nth-child(odd) {
							width: calc(50% - #{$base-margin});
						}
						&:nth-child(even) {
							width: 50%;
						}
						&.type-size-full {
							width: 100%;
							margin-right: 0;
						}
						&.type-size-quarter {
							width: calc(25% - #{$base-margin});
							margin-bottom: $base-margin !important;
						}
						&:last-child {
							margin-bottom: 0;
						}
						@include breakpoint(small) {
							&:nth-child(odd),
							&:nth-child(even) {
								width: 100%;
							}
						}
					}
				}

			//
			// Field Groups
			//

				.field-group {
					position: relative;
					@include mainOffsetPanel;
					.field-grid {
						margin-bottom: 0;
						padding-bottom: 0;
					}
					&:before {
						border: 1px solid $form-set-group-border-color;
					}
					@include breakpoint(medium) {
						padding: $base-padding / 1.5;
					}
				}

			//
			// Radio Group
			//

				.field-option-group {
					&.type-inline {
						.field-option {
							display: inline-block;
							vertical-align: middle;
							margin-right: $base-margin;
							&:last-child {
								margin-right: 0;
							}
						}
					}
				}

			//
			// Sizes & Types
			//

				input,
				select,
				button {
					&.offset-label {
						position: absolute;
						bottom: 0;
						@include breakpoint(small) {
							position: relative;
						}
					}
					&.type-center {
						text-align: center;
					}
				}

				input,
				select {
					&.size-small {
						width: 50px !important;
					}
				}

			//
			// Validation
			//

				input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]),
				select {
					&.validate-invalid {
						border-color: $validation-field-error-border-color;
					}
				}

				.validate-alert {
					position: relative;
					top: 0;
					left: 0;
					color: $validation-alert-error-color;
					background-color: $validation-alert-error-background-color;
					font-size: $validation-alert-font-size;
					display: block;
					margin-top: $validation-alert-margin-top;
					padding: $validation-alert-padding;
					width: auto;
					height: auto;
					border-radius: 0;
					bottom: auto;
					right: auto;
					position: relative;
					font-weight: 600;
					text-align: left;
					text-transform:  uppercase;
				}

			//
			// Field Icon
			//

				.field-icon-container {
					position: relative;
				}

				.field-icon {
					font-size: 22px;
					position: absolute;
					top: 50%;
					transform: translateY(-50%);
					right: $form-input-padding;
					pointer-events: none;
				}

			//
			// Stripe
			//

				.stripe-errors {
					color: colour(error);
					font-weight: 600;
					font-size: $validation-alert-font-size;
					margin-bottom: $base-margin;
				}

				.stripe-input {
					padding: $form-input-padding * 1.2;
					border-radius: $form-input-border-radius;
					border: 1px solid $form-input-border-color;
					background-color: $form-input-background-color;
					transition: $base-transition;
					box-shadow: $form-input-box-shadow;
					color: $form-input-color;
					cursor: text;
					@include breakpoint(small) {
						padding: $form-input-padding / 1.2;
					}
					&:hover {
						border-color: darken($form-input-border-color, 20%);
					}
					&.StripeElement--invalid {
						border-color: colour(error);
					}
					&.StripeElement--focus {
						box-shadow: 0px 0px 10px rgba(#000, 0.2);
						outline: none;
						border-color: darken($form-input-border-color, 10%);
						background-color: #FFF;
						&.StripeElement--invalid {
							border-color: colour(error);
						}
					}
				}

		}
	}
